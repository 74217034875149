<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <div class="card shadow-sm">
                    <div class="card-body py-3">
                        <div class="card-action card-action--with-title mb-0">
                            <div>
                                <a href="javascript:history.back()" class="btn btn-white">
                                    <i class="fe fe-arrow-left mr-2"></i>Kembali
                                </a>
                            </div>
                            <div class="card-action--title ml-3">
                                <div class="font-weight-bolder text-md">Tambah pengumuman Baru</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- FORM -->
                <form @submit.prevent="submitForm" enctype="multipart/form-data" class="mb-4">
                    <div class="card shadow-sm">
                        <div class="card-header">
                            <span class="font-weight-bolder">Informasi pengumuman</span>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label>Title<span class="text-primary">*</span></label>
                                <input type="text" v-model="form.title" class="form-control" v-on:keypress="convertToSlug()">
                            </div>
                            <div class="form-group">
                                {{ slug }}
                                <label>Slug<span class="text-primary">*</span></label>
                                <input type="text" v-model="form.slug" class="form-control" readonly>
                            </div>
                            <div class="form-group">
                                <!-- Label -->
                                <label class="mb-1">
                                    Isi pengumuman
                                </label>
                                <!-- Textarea -->
                                <!-- <ckeditor v-model="form.description" :config="editorConfig" style=""></ckeditor> -->
                                <vue-simplemde v-model="form.description" :configs="configEditor" ref="markdownEditor" />
                            </div>
                        </div>
                    </div>

                    <!-- Action -->
                    <div class="d-flex justify-content-end">
                        <a href="javascript:history.back()" class="btn text-muted">Batal & Kembali</a>
                        <button class="btn btn-primary ml-2"><i class="fe fe-save mr-2"></i>Simpan & Buat Pengumuman</button>
                    </div>
                    <div class="spacer"></div>
                </form>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                form: {
                    title: '',
                    slug: '',
                    description: '',
                    author: 'Admin',
                    type: 'announcement',
                    status: 'publish'
                },
                configEditor: {
                    placeholder: 'Masukan isi berita disini...',
                    spellChecker: false,
                    autofocus: false,
                    // toolbar: false,
                }
            }
        },
        computed: {
            simplemde() {
                return this.$refs.markdownEditor.simplemde;
            },
        },
        methods: {
            convertToSlug() {
                var slug = "";
                var titleLower = this.form.title.toLowerCase();
                slug = titleLower.replace(/đ/gi, 'd');
                slug = slug.replace(/\s*$/g, '');
                slug = slug.replace(/\s+/g, '-');
                this.form.slug = slug;
            },
            submitForm() {
                if (this.form.title == '' || this.form.description == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'inputan tidak boleh kosong',
                    })
                } else {
                    this.$http.post(this.baseUrl + 'admin/blog/save', this.form)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: "success",
                                text: 'Data pengumuman berhasil disimpan',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            this.form.title = ''
                            this.form.slug = ''
                            this.form.description = ''
                            this.form.author = ''
                            this.form.status = ''
                            this.form.type = ''
                            this.$router.push({
                                name: 'pengumuman.index'
                            })
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        }).finally(() => {
                            //Perform action in always
                        });
                }
            },
        },
    };
</script>